import { autoserializeAs, autoserializeAsArray } from 'dcerialize';
import { UserStatus } from 'src/definitions/user-status.enum';

/**
 * Base Operator class
 */
export class Operator {
  /**
   * Identification attributes
   */
  @autoserializeAs(() => Number) id?: number; // Operator ID
  @autoserializeAs(() => String) name?: string; // Operator name
  @autoserializeAs(() => String, 'last_name') lastName?: string; // Operator last name
  @autoserializeAs(() => String, 'business_name') businessName?: string; // Operator business name
  @autoserializeAs(() => Number) phone?: number; // Operator phone
  @autoserializeAs(() => String) email?: string; // Operator email
  @autoserializeAs(() => String) cif?: string; // Operator CIF
  @autoserializeAs(() => String, 'document_id') documentId?: string; // Operator document ID
  @autoserializeAs(() => String) password?: string; // Operator password
  @autoserializeAs(() => Number, 'emergency_phone') emergencyPhone?: number; // Operator emergency phone
  @autoserializeAs(() => String, 'microsite_id') micrositeId?: string; // Operator microsite
  @autoserializeAs(() => String) logo?: string; // Operator logo

  /**
   * Address attributes
   */
  @autoserializeAs(() => String) address?: string; // Operator base address
  @autoserializeAs(() => String) locality?: string; // Operator base locality
  @autoserializeAs(() => Number, 'postal_code') postalCode?: number; // Operator address postal code
  @autoserializeAs(() => String) province?: string; // Operator address province

  /**
   * Payment attributes
   */
  @autoserializeAs(() => String, 'bank_account') bankAccount?: string; // Operator bank account

  /**
   * Profile attributes
   */
  @autoserializeAs(() => Number, 'total_reservations') totalReservations?: number; // Nº of operator reservations
  // Nº of operator finished reservations
  @autoserializeAs(() => Number, 'total_finished_reservations') totalFinishedReservations?: number;
  @autoserializeAs(() => Number, 'total_spent') totalSpent?: number; // Total amount spent on operator
  @autoserializeAs(() => Number, 'total_review_score') totalReviewScore?: number; // Mean score of operator reviews
  @autoserializeAs(() => Number, 'total_pending') totalPending?: number; // Nº of pending operator reservations
  @autoserializeAs(() => Number) profit?: number; // Operator profit

  /**
   * Operator List attributes
   */
  @autoserializeAs(() => String) status?: UserStatus; // State of the operator account
  @autoserializeAs(() => Number, 'total_trips') totalTrips?: number; // Trips made by the operator
  @autoserializeAs(() => Number, 'total_vehicles') totalVehicles?: number; // Vehicles belonging to the operator
  @autoserializeAs(() => Number, 'total_billed') totalBilled?: number; // Money billed the operator

  constructor(
    name?: string,
    businessName?: string,
    phone?: number,
    email?: string,
    cif?: string,
    documentId?: string,
    password?: string,
    micrositeId?: string,
    address?: string,
    postalCode?: number,
    province?: string,
    bankAccount?: string,
    totalReservations?: number,
    totalSpent?: number,
    totalReviewScore?: number,
    status?: UserStatus,
    totalTrips?: number,
    totalVehicles?: number,
    totalBilled?: number,
    emergencyPhone?: number,
    logo?: string,
    profit?: number,
    totalFinishedReservations?: number
  ) {
    this.name = name;
    this.businessName = businessName;
    this.phone = phone;
    this.email = email;
    this.address = address;
    this.postalCode = postalCode;
    this.province = province;
    this.cif = cif;
    this.password = password;
    this.micrositeId = micrositeId;
    this.documentId = documentId;
    this.bankAccount = bankAccount;
    this.totalReservations = totalReservations;
    this.totalSpent = totalSpent;
    this.totalReviewScore = totalReviewScore;
    this.status = status;
    this.totalTrips = totalTrips;
    this.totalVehicles = totalVehicles;
    this.totalBilled = totalBilled;
    this.emergencyPhone = emergencyPhone;
    this.logo = logo;
    this.profit = profit;
    this.totalFinishedReservations = totalFinishedReservations;
  }
}

export class OperatorList {
  @autoserializeAsArray(() => Operator) items: Operator[];
  @autoserializeAs(() => Number) total: number;

  constructor(items: Operator[] = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}
