import { autoserializeAs, autoserializeAsArray, onDeserialized } from 'dcerialize';

import { UserRole } from '../definitions/user-role.enum';
import { UserStatus } from '../definitions/user-status.enum';

/**
 * User CRUD class
 */
export class User {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id: number;

  /**
   * Document id
   */
  @autoserializeAs(() => String, 'document_id') documentId: string;

  /**
   * User email
   */
  @autoserializeAs(() => String) email?: string;

  /**
   * User name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * User last name
   */
  @autoserializeAs(() => String, 'last_name') lastName?: string;

  /**
   * User address
   */
  @autoserializeAs(() => String) address?: string;

  /**
   * User location
   */
  @autoserializeAs(() => String) locality?: string;

  /**
   * User city
   */
  @autoserializeAs(() => String) province?: string;

  /**
   * User postalCode
   */
  @autoserializeAs(() => String, 'postal_code') postalCode?: string;

  /**
   * User phone
   */
  @autoserializeAs(() => String) phone?: string;

  /**
   * Business name
   */
  @autoserializeAs(() => String, 'business_name') businessName?: string;

  /**
   * Bank account
   */
  @autoserializeAs(() => String, 'bank_account') bankAccount?: string;

  /**
   * Number of logins
   */
  @autoserializeAs(() => Number, 'login_count') loginCount?: number;

  /**
   * When the user was logged in
   */
  @autoserializeAs(() => Date, 'logged_at') loggedAt?: Date;

  /**
   * Whether the user is disabled
   */
  @autoserializeAs(() => Boolean) disabled?: boolean;

  /**
   * User role
   */
  @autoserializeAs(() => String, 'user_type') userType?: UserRole;

  /**
   * User logo
   */
  @autoserializeAs(() => String) logo?: string;

  /**
   * Total spent
   */
  @autoserializeAs(() => Number, 'total_spent') totalSpent?: number;

  /**
   * Created at
   */
  @autoserializeAs(() => Date, 'created_at') createdAt?: Date;

  /**
   * State of the user account
   */
  @autoserializeAs(() => String) status?: UserStatus;

  /**
   * Trips made by the user
   */
  @autoserializeAs(() => Number, 'total_trips') totalTrips?: number;

  /**
   * Money billed by the user
   */
  @autoserializeAs(() => Number, 'total_billed') totalBilled?: number;

  /**
   * Full name
   */
  @autoserializeAs(() => String, 'full_name') fullName?: string;

  @onDeserialized
  checkName(): void {
    this.name = this.name ? this.name : '';
    this.lastName = this.lastName ? this.lastName : '';
  }

  constructor(
    id: number,
    documentId: string,
    name?: string,
    email?: string,
    lastName?: string,
    address?: string,
    locality?: string,
    province?: string,
    postalCode?: string,
    phone?: string,
    businessName?: string,
    bankAccount?: string,
    loginCount?: number,
    loggedAt?: Date,
    disabled?: boolean,
    userType?: UserRole,
    logo?: string,
    totalSpent?: number,
    createdAt?: Date,
    status?: UserStatus,
    totalTrips?: number,
    totalBilled?: number,
    fullName?: string
  ) {
    this.id = id;
    this.documentId = documentId;
    this.name = name;
    this.email = email;
    this.lastName = lastName;
    this.address = address;
    this.locality = locality;
    this.province = province;
    this.postalCode = postalCode;
    this.phone = phone;
    this.businessName = businessName;
    this.bankAccount = bankAccount;
    this.loginCount = loginCount;
    this.disabled = disabled;
    this.loggedAt = loggedAt;
    this.userType = userType;
    this.logo = logo;
    this.totalSpent = totalSpent;
    this.createdAt = createdAt;
    this.status = status;
    this.totalTrips = totalTrips;
    this.totalBilled = totalBilled;
    this.fullName = fullName;
  }
}

export class UserList {
  @autoserializeAsArray(() => User) items: User[];
  @autoserializeAs(() => Number) total: number;

  constructor(items: User[] = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}

export class UserData {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id: number;

  /**
   * User email
   */
  @autoserializeAs(() => String) email?: string;

  /**
   * User name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * User last name
   */
  @autoserializeAs(() => String, 'last_name') lastName?: string;

  /**
   * Business name
   */
  @autoserializeAs(() => String) businessName?: string;

  /**
   * Logo
   */
  @autoserializeAs(() => String) logo?: string;

  /**
   * User type
   */
  @autoserializeAs(() => String, 'user_type') userType?: UserRole;

  constructor(
    id: number,
    email?: string,
    name?: string,
    lastName?: string,
    businessName?: string,
    logo?: string,
    userType?: UserRole
  ) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.lastName = lastName;
    this.businessName = businessName;
    this.logo = logo;
    this.userType = userType;
  }
}

export class UserDataPassword {
  /**
   * Current Password
   */
  @autoserializeAs(() => String, 'current_password') currentPassword: string;

  /**
   * Password
   */
  @autoserializeAs(() => String) password: string;

  /**
   * Type
   */
  @autoserializeAs(() => String) type: string;

  constructor(currentPassword: string, password: string, type: string) {
    this.currentPassword = currentPassword;
    this.password = password;
    this.type = type;
  }
}

export class Client {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id: number;

  /**
   * Client name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * Client last name
   */
  @autoserializeAs(() => String, 'last_name') lastName?: string;

  /**
   * Client email
   */
  @autoserializeAs(() => String) email?: string;

  /**
   * Client phone
   */
  @autoserializeAs(() => Number) phone?: string;

  /**
   * Number of reservations
   */
  @autoserializeAs(() => Number, 'total_reservations') totalReservations?: number;

  /**
   * Total spent with VAT
   */
  @autoserializeAs(() => Number, 'total_spent_taxes') totalSpentTaxes?: number;

  /**
   * Total spent with VAT and fees
   */
  @autoserializeAs(() => Number, 'total_spent_net') totalSpentNet?: number;

  /**
   * Mean score
   */
  @autoserializeAs(() => Number, 'total_review_score') totalReviewScore?: number;

  /**
   * Created by
   */
  @autoserializeAs(() => Number, 'created_by') createdBy?: number;

  /**
   * State of the client account
   */
  @autoserializeAs(() => String) status?: UserStatus;

  /**
   * Total pending reservations
   */
  @autoserializeAs(() => Number, 'total_pending') totalPending?: number;

  constructor(
    id: number,
    name?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    totalReservations?: number,
    totalSpentTaxes?: number,
    totalSpentNet?: number,
    totalReviewScore?: number,
    createdBy?: number,
    status?: UserStatus,
    totalPending?: number
  ) {
    this.id = id;
    this.name = name;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.totalReservations = totalReservations;
    this.totalSpentTaxes = totalSpentTaxes;
    this.totalSpentNet = totalSpentNet;
    this.totalReviewScore = totalReviewScore;
    this.createdBy = createdBy;
    this.status = status;
    this.totalPending = totalPending;
  }

  fullName(): string {
    return `${this.name} ${this.lastName}`;
  }
  @onDeserialized
  checkName(): void {
    this.name = this.name ? this.name : '';
    this.lastName = this.lastName ? this.lastName : '';
  }
}

export class UserSupport {
  /**
   * Name
   */
  @autoserializeAs(() => String) name: string;

  /**
   * Email
   */
  @autoserializeAs(() => String) email: string;

  /**
   * User type
   */
  @autoserializeAs(() => String, 'user_type') userType: UserRole;

  /**
   * Subject
   */
  @autoserializeAs(() => String) subject: string;

  /**
   * Message
   */
  @autoserializeAs(() => String, 'message_content') messageContent: string;

  /**
   * reCAPTCHA token
   */
  @autoserializeAs(() => String) recaptcha: string;

  constructor(
    name: string,
    email: string,
    userType: UserRole,
    subject: string,
    messageContent: string,
    recaptcha: string
  ) {
    this.name = name;
    this.email = email;
    this.userType = userType;
    this.subject = subject;
    this.messageContent = messageContent;
    this.recaptcha = recaptcha;
  }
}
